import React from 'react'
import styled, { css, keyframes } from 'styled-components'

import { rspTheme } from '../../../styles/rspTheme'
import { colorObject } from '../LinkCTA'

interface ButtonProps {
  children: React.ReactChild
  variant?: 'primary' | 'secondary' | 'outline' | 'ghost'
  size?: 'buttonLg' | 'buttonMd' | 'buttonSm'
  type?: 'button' | 'submit' | 'reset'
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
  isLoading?: boolean
}

interface StyledButtonProps {
  size: string
  variant: string
  isLoading: boolean
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const StyledButton = styled.button<StyledButtonProps>`
  color: ${({ variant }) => colorObject[variant].text};
  background-color: ${({ variant }) => colorObject[variant].background};
  border: ${({ variant }) => colorObject[variant].border};
  font-size: ${({ size }) => `${rspTheme.typography[size].fontSize}`};
  font-weight: ${({ size }) => `${rspTheme.typography[size].fontWeight}`};
  line-height: ${({ size }) => `${rspTheme.typography[size].lineHeight}`};
  text-transform: uppercase;
  padding: 6px 22px;
  border-radius: 4px;
  transition: all 0.25s ease-in-out;
  position: relative;

  path {
    transition: all 0.25s ease-in-out;
    fill: ${({ variant }) => colorObject[variant].pathFill};
  }

  &:not(:disabled) {
    &:hover,
    &:focus {
      color: ${({ variant }) => colorObject[variant].focus.text};
      background-color: ${({ variant }) =>
        colorObject[variant].focus.background};
      border: ${({ variant }) => colorObject[variant].focus.border};

      path {
        fill: ${({ variant }) => colorObject[variant].focus.pathFill};
      }
    }
  }

  &:disabled {
    opacity: 0.3;
    pointer-events: none !important;
  }

  ${({ isLoading, variant }) =>
    isLoading &&
    css`
      pointer-events: none !important;
      background-color: ${colorObject[variant].background} !important;
      opacity: 1 !important;

      .children {
        visibility: hidden !important;
      }
    `}
`

export const ButtonLoadingSpinner = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  overflow: hidden;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  &:before {
    content: '';
    width: 28px;
    height: 28px;
    border: 2px solid #fff;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: ${rotate} 0.6s linear infinite;
    position: absolute;
    left: calc(50% - 14px);
    top: calc(50% - 14px);
  }
`

const Button = (props: ButtonProps) => {
  const {
    children,
    onClick,
    variant = 'primary',
    size = 'buttonLg',
    type = 'button',
    disabled = false,
    isLoading = false,
  } = props

  return (
    <StyledButton
      disabled={disabled || isLoading}
      isLoading={isLoading}
      onClick={onClick}
      size={size}
      type={type}
      variant={variant}
    >
      {isLoading ? <ButtonLoadingSpinner /> : null}
      <span className="children">{children}</span>
    </StyledButton>
  )
}

export default Button
