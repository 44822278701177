import { graphql, HeadProps, PageProps } from 'gatsby'
import React from 'react'

import Layout from '../components/Layout'
import NewsletterSignup from '../components/NewsletterSignup'
import PageBody from '../components/PageBody'
import RelatedResources from '../components/RelatedResources'
import RelatedSolutions from '../components/RelatedSolutions'
import ResourceHero from '../components/ResourceHero'
import { SchemaMarkup } from '../components/SchemaMarkup/SchemaMarkup'
import { SchemaMarkupBreadcrumb } from '../components/SchemaMarkup/SchemaMarkupBreadcrumb'
import { useTrackResourcePage } from '../utils/dataLayer'

const NonDownloadableResourcePage = ({
  data,
  pageContext,
}: PageProps<
  Queries.nonDownloadableResourcePageQuery,
  { filterType: string; slug: string }
>) => {
  const pageData = data?.contentstackPageResource
  const newsletterFormContent = data?.newsletterSignupContent?.nodes?.[0]
  const parentUrl = data?.parentUrl
  const breadcrumbLinks = [
    { text: parentUrl?.title || '', target: parentUrl?.pageUrl || '/' },
    { text: pageData?.title || '' },
  ]

  let resourcesByFilter
  switch (pageContext.filterType) {
    case 'Contentstack_topic_resource_type':
      resourcesByFilter = [
        ...(data?.resourceCardsByResourceTypeFilter?.nodes || []),
      ]
      break
    case 'Contentstack_topic_industry':
      resourcesByFilter = [
        ...(data?.resourceCardsByIndustryFilter?.nodes || []),
      ]
      break
    case 'Contentstack_topic_role':
      resourcesByFilter = [...(data?.resourceCardsByRoleFilter?.nodes || [])]
      break
    default:
      resourcesByFilter = []
      break
  }

  const allFeaturedResourceCards = data?.allFeaturedResourceCards?.nodes || []
  if (resourcesByFilter.length === 0) {
    allFeaturedResourceCards?.forEach((resource: any) =>
      resourcesByFilter.push(resource),
    )
  }

  pageData?.resource_grid?.[0]?.promoted_resources?.forEach((resource: any) =>
    resourcesByFilter.push(resource),
  )

  const allProductPages = data?.productCards?.nodes
  const productPage =
    allProductPages?.[Math.floor(Math.random() * allProductPages.length)]
  const solutionsContent = pageData?.featured_solutions?.[0]
    ? {
        ...pageData?.featured_solutions?.[0],
        cta_text: data?.solutionsCarousel?.nodes?.[0]?.cta_text,
      }
    : data?.solutionsCarousel?.nodes?.[0]

  const pageBreakProduct = {
    headline: productPage?.product?.[0]?.headline,
    short_description: productPage?.product?.[0]?.short_description,
    side_cta_short_description:
      productPage?.product?.[0]?.side_cta_short_description,
    feature_image: productPage?.product?.[0]?.feature_image,
    linkTarget: productPage?.url,
    linkText:
      data?.allContentstackResourcesWebsite?.nodes?.[0]?.promo_card_cta_text,
  }

  const metaTitle =
    pageData?.metadata?.page_title ||
    pageData?.title ||
    pageData?.page_header?.[0]?.headline ||
    pageData?.page_header?.[0]?.topic?.[0]?.headline
  const metaDesc =
    pageData?.metadata?.description ||
    pageData?.page_header?.[0]?.subheading ||
    pageData?.page_header?.[0]?.topic?.[0]?.subheading
  const metaImg =
    pageData?.metadata?.image ||
    pageData?.page_header?.[0]?.feature_image ||
    pageData?.page_header?.[0]?.topic?.[0]?.feature_image

  const downloadResourceObject = [
    {
      headline:
        data?.downloadResourceCard?.nodes?.[0]?.resource?.[0]?.headline || '',
      feature_image:
        data?.downloadResourceCard?.nodes?.[0]?.resource?.[0]?.feature_image ||
        '',
      linkTarget: data?.downloadResourceCard?.nodes?.[0]?.url || '',
      linkText:
        data?.allContentstackResourcesWebsite?.nodes?.[0]
          ?.download_card_cta_text || '',
      side_cta_short_description:
        data?.downloadResourceCard?.nodes?.[0]?.resource?.[0]
          ?.side_cta_short_description || '',
    },
  ]

  useTrackResourcePage(data)

  return (
    <Layout
      breadcrumbLinks={breadcrumbLinks}
      description={metaDesc}
      image={metaImg}
      locale={pageData?.publish_details?.locale}
      slug={pageContext.slug}
      title={metaTitle}
    >
      <ResourceHero
        author={pageData?.resource?.[0]?.author?.[0]?.name}
        featureImage={pageData?.resource?.[0]?.feature_image}
        headline={pageData?.resource?.[0]?.headline || ''}
        locale={pageData?.locale}
        pubDate={pageData?.resource?.[0]?.publication_date}
        resourceType={pageData?.resource?.[0]?.resource_type?.[0]?.title}
        topicTag={pageData?.resource?.[0]?.resource_topic?.[0]?.headline}
      />
      <PageBody
        content={{
          show_sticky_nav: 'True',
          show_download_cta_button: downloadResourceObject?.[0]?.linkTarget
            ? 'True'
            : 'False',
          topic: [
            {
              long_description: pageData?.resource?.[0]?.body || '',
              download_resource: downloadResourceObject,
            },
          ],
          showShareLinks: true,
          page_break: [
            {
              product: productPage?.product?.[0] ? pageBreakProduct : null,
            },
          ],
          show_download_card: downloadResourceObject?.[0]?.linkTarget
            ? 'True'
            : 'False',
        }}
      />
      <NewsletterSignup content={newsletterFormContent} />
      {pageData?.resource_grid?.[0] ? (
        <RelatedResources
          content={{
            ...pageData?.resource_grid?.[0],
            promoted_resources: resourcesByFilter,
          }}
        />
      ) : null}
      {solutionsContent ? (
        <RelatedSolutions relatedSolutions={solutionsContent} />
      ) : null}
    </Layout>
  )
}

export const Head = ({
  data,
}: HeadProps<Queries.nonDownloadableResourcePageQuery>) => (
  <>
    <SchemaMarkup data={data.contentstackPageResource?.schema_markup} />
    <SchemaMarkupBreadcrumb
      pageTitle={data?.contentstackPageResource?.title}
      parent={data?.parentUrl}
    />
  </>
)

export default NonDownloadableResourcePage

export const data = graphql`
  query nonDownloadableResourcePage(
    $pageId: String
    $downloadResourceId: String
    $productId: String
    $locale: String
    $filterId: String
  ) {
    contentstackPageResource(
      id: { eq: $pageId }
      publish_details: { locale: { eq: $locale } }
    ) {
      id
      title
      locale
      chinese_version_url
      publish_details {
        locale
      }
      metadata {
        page_title
        description
        image {
          description
          url
        }
      }
      schema_markup {
        ...SchemaMarkup
      }
      resource {
        ... on Contentstack_topic_resource {
          is_gated
          id
          headline
          body
          resource_topic {
            headline
          }
          resource_type {
            title
          }
          author {
            name
          }
          publication_date
          feature_image {
            description
            imgixImage {
              gatsbyImageData(width: 1140, imgixParams: { q: 65 })
            }
          }
          products {
            ... on Contentstack_topic_product {
              id
              headline
              short_description
              feature_image {
                description
                imgixImage {
                  gatsbyImageData(width: 485, imgixParams: { q: 65 })
                }
              }
            }
            ... on Contentstack_topic_training_program {
              id
              headline
              short_description
              feature_image {
                description
                imgixImage {
                  gatsbyImageData(width: 485, imgixParams: { q: 65 })
                }
              }
            }
          }
        }
      }
      resource_grid {
        ...RelatedResourcesSection
      }
      featured_solutions {
        ...FeaturedProgramsSection
      }
    }

    downloadResourceCard: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: {
          elemMatch: {
            is_gated: { eq: "Yes" }
            id: { eq: $downloadResourceId }
          }
        }
      }
    ) {
      nodes {
        title
        url
        resource {
          headline
          side_cta_short_description
          feature_image {
            description
            imgixImage {
              gatsbyImageData(width: 374, imgixParams: { q: 60 })
            }
          }
        }
      }
    }

    resourceCardsByRoleFilter: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: {
          elemMatch: { roles: { elemMatch: { id: { eq: $filterId } } } }
        }
      }
      limit: 15
    ) {
      nodes {
        id
        title
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }

    resourceCardsByResourceTypeFilter: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: {
          elemMatch: { resource_type: { elemMatch: { id: { eq: $filterId } } } }
        }
      }
      limit: 15
    ) {
      nodes {
        id
        title
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }

    resourceCardsByIndustryFilter: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: {
          elemMatch: { industry: { elemMatch: { id: { eq: $filterId } } } }
        }
      }
      limit: 15
    ) {
      nodes {
        id
        title
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }

    allFeaturedResourceCards: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: { elemMatch: { is_featured: { eq: "Yes" } } }
      }
      sort: { fields: resource___publication_date, order: DESC }
      limit: 15
    ) {
      nodes {
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }

    productCards: allContentstackPageProduct(
      filter: {
        publish_details: { locale: { eq: $locale } }
        product: { elemMatch: { id: { eq: $productId } } }
      }
    ) {
      nodes {
        id
        url
        product {
          id
          headline
          short_description
          feature_image {
            description
            imgixImage {
              gatsbyImageData(width: 485, imgixParams: { q: 60 })
            }
          }
        }
      }
    }

    newsletterSignupContent: allContentstackComponentSubscribeForm(
      filter: {
        publish_details: { locale: { eq: $locale } }
        uid: { eq: "bltad835be70841cee4" }
      }
    ) {
      nodes {
        headline
        short_description
        first_name_label {
          copy
        }
        error_message {
          copy
        }
        confirmation_message {
          copy
        }
        submit_cta {
          copy
        }
        small_text {
          ... on Contentstack_microcopy_resource {
            id
            copy
          }
          ... on Contentstack_topic_link {
            ...NavigationLinkFragment
          }
        }
      }
    }

    solutionsCarousel: allContentstackSectionSolutionsCardCarousel(
      filter: {
        title: { eq: "Solutions Card Carousel - Global" }
        publish_details: { locale: { eq: $locale } }
      }
    ) {
      nodes {
        ...FeaturedProgramsSection
      }
    }

    parentUrl: contentstackPageResourcesOverview(
      publish_details: { locale: { eq: $locale } }
    ) {
      title
      pageUrl: url
    }

    allContentstackResourcesWebsite(
      filter: { publish_details: { locale: { eq: $locale } } }
    ) {
      nodes {
        download_card_cta_text
        promo_card_cta_text
      }
    }
  }
`
